import * as React from "react";
import classNames from "classnames";
import RichText from "prismic-reactjs/src/Component";

const PricingItem = (props) => {

    const pricingItemClassnames = classNames(
        'pricing-item',
        {'pricing-item__best-offer': props.isBestOffer}
    );

    return (
        <div className={pricingItemClassnames}>
            <div className="pricing-item__content">
                <div className="pricing-item__title">{props.plan_title}{props.plan_tag ? <span className="pricing-item__tag">{props.plan_tag}</span> : null}</div>
                <div className="pricing-item__subtitle">{props.plan_subtitle}</div>
                <div className="pricing-item__price"><span className="pricing-item__amount">{props.price_amount}€</span><span className="pricing-item__unit">{props.unit}</span></div>
                <div className="pricing-item__description">
                    {RichText.render(props.plan_description.raw)}
                </div>
            </div>
        </div>
    )
}

export default PricingItem;
