import * as React from "react";
import RichText from "prismic-reactjs/src/Component";
import Img from "gatsby-image";

const Benefits = ({image, title, richText}) => {
    return (
        <section className="section section--with-padding section-benefits">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="section-benefits__image">
                            <Img fluid={image.fluid} alt={image.alt} />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="section-benefits__content">
                            <div>
                                <h2 className="section__title text-center text-lg-start">{title}</h2>
                                {RichText.render(richText.raw)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits;
