import * as React from "react";
import PricingItem from "./PricingItem";

const PRICING_ITEM_COL_LAYOUT = "col-12 col-lg-4";

const Pricing = ({title, items}) => {
    return (
        <section className="section section--with-padding section-pricing">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="section__title text-center">{title}</h2>
                        <div className="section__content section__content--with-padding">
                            <div className="row">
                                {items.map( (item, index) =>
                                    <div key={index} className={PRICING_ITEM_COL_LAYOUT}>
                                        <PricingItem {...item} isBestOffer={index === 1}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing;
