import * as React from "react";
import RichText from "prismic-reactjs/src/Component";

const SystemRequirements = ({title, subtitle, textContent}) => {
    return (
        <section className="section section--with-padding section-sysreq">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="section__title text-center text-md-start">{title}</h2>
                        <div className="section__subtitle">
                            {RichText.render(subtitle.raw)}
                        </div>
                        <div className="section-sysreq__wrapper section__content">
                            <div className="row g-5">
                                <div className="col-sm-4">
                                    <div className="section-sysreq__content">
                                        {RichText.render(textContent.raw)}
                                    </div>
                                </div>
                                <div className="col-sm-8">

                                    <div className="sysreq-general-title">Downloads</div>

                                    <div className="sysreq-wrapper">
                                        <div className="row g-5">
                                            <div className="col-md-6">
                                                <div className="sysreq-table">
                                                    <h3 className="sysreq-table__title">Daylicious <span>Manager</span></h3>
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td>Mac</td>
                                                            <td><a href="https://api.dailycious.io/download/manager" target="_blank">≥ OSX 10.14</a></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="sysreq-table">
                                                    <h3 className="sysreq-table__title">Daylicious <span>Player</span></h3>

                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td>iOS (iPhone/iPad)</td>
                                                            <td><a href="https://api.dailycious.io/download/player" target="_blank">≥ iOS 12</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mac</td>
                                                            <td><a href="https://api.dailycious.io/download/player" target="_blank">≥ OSX 10.14</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Microsoft</td>
                                                            <td><a href="https://api.dailycious.io/download/player" target="_blank">≥ Windows 7</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Android</td>
                                                            <td>Coming Soon</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className={'text-center'}>
                                        For any technical questions, please contact <a href="mailto:support@dailycious.io">support@dailycious.io</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default SystemRequirements
