import * as React from "react";

const Hero = ({children}) => {
    return (
        <section className="section section-hero">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {children}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;
