import * as React from "react";
import HowDoesItWorkItem from "./HowDoesItWorkItem";

const FEATURE_ITEM_COL_LAYOUT = "col-12 col-lg-4";

const HowDoesItWork = ({title, items}) => {
    return (
        <section className="section section--with-padding section-hdiw">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="section__title text-center">{title}</h2>
                        <div className="section__content section__content--with-padding">
                            <div className="row">
                                {items.map( (item, index) =>
                                    <div key={index} className={FEATURE_ITEM_COL_LAYOUT}>
                                        <HowDoesItWorkItem {...item} />
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="section-hdiw__footer">
                                        <div className="text-center">
                                            <a target="_blank" href="https://www.notion.so/Dailycious-User-Guide-dc1557366bdc4abb94bc5386c89b5423" rel="nofollow" className="button-1 section-hdiw__see-user-guide">User guide</a>
                                        </div>
                                        <p>
                                            *compatible with DaVinci Resolve, Avid Media Composer, Pomfort Silverstack, Filmlight Daylight, Colorfront OSD/EXD.<br/>
                                            Please read the user guide or contact support for further details.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowDoesItWork;
