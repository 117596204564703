import * as React from "react";
import RichText from "prismic-reactjs/src/Component";

const HowDoesItWorkItem = (props) => {

    return (
        <div className="hdiw-item text-center">
            <div className="hdiw-item__icon">
                <img src={props.icon.url} />
            </div>
            <div className="hdiw-item__metas">
                <h3 className="hdiw-item__title">
                    {props.title}
                </h3>
                {RichText.render(props.text_content.raw)}
            </div>
        </div>
    )
}

export default HowDoesItWorkItem;
