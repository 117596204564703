import * as React from "react";
import PoweredBySlider from "./PoweredBySlider";
import RichText from "prismic-reactjs/src/Component";


const shuffleArray = (array) => {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        const rand = Math.random();

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }


    return array;
}

const PoweredBy = ({title, subtitle, images}) => {
    return (
        <section className="section section-powered-by">

            <div className="section-powered-by__slider-wrapper">
                <PoweredBySlider images={shuffleArray(images)} />
                <PoweredBySlider images={shuffleArray(images)} reverse={true} />
                <PoweredBySlider images={shuffleArray(images)} />
                <PoweredBySlider images={shuffleArray(images)} reverse={true} />
                <PoweredBySlider images={shuffleArray(images)} />
                <PoweredBySlider images={shuffleArray(images)} reverse={true}  />
                <PoweredBySlider images={shuffleArray(images)} />
                <PoweredBySlider images={shuffleArray(images)} reverse={true} />
                <PoweredBySlider images={shuffleArray(images)} />
                <PoweredBySlider images={shuffleArray(images)} reverse={true} />
                <PoweredBySlider images={shuffleArray(images)} />
                <PoweredBySlider images={shuffleArray(images)} reverse={true} />
            </div>

            <div className="section-powered-by__gradient" />

            <div className="section-powered-by__content text-center">
                <h2 className="section__title">{title}</h2>
                <div className="section__subtitle text-center">
                    {RichText.render(subtitle.raw)}
                </div>
            </div>

        </section>
    )

}

export default PoweredBy
