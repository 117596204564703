import * as React from "react"
import { graphql } from 'gatsby'
import DailyciousLogo from "../assets/dailycious-logo.svg";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Features from "../components/Features/Features";
import Benefits from "../components/Benefits";
import HowDoesItWork from "../components/HowDoesItWork/HowDoesItWork";
import Pricing from "../components/Pricing/Pricing";
import SystemRequirements from "../components/SystemRequirements";
import PoweredBy from "../components/PoweredBy/PoweredBy";

const IndexPage = ({location, path, data}) => {
    if (!data) return null
    const slices = data.prismicHomepage.data.body;

    return (
        <Layout location={location} path={path}>
            <Hero>
                <div className="hero-wrapper">
                    <div className="hero-logo">
                        <DailyciousLogo />
                        <p className="hero-logo__baseline">watch your dailies</p>
                    </div>
                </div>
            </Hero>
            {slices.map((slice, index) => {
                switch (slice.slice_type) {
                    case 'features':
                        return <div id="features" key={index}>
                            <Features title={slice.primary.section_title.text} items={slice.items}  />
                        </div>;
                    case 'featured_image':
                        return <div id="benefits" key={index}>
                            <Benefits title={slice.primary.title.text} image={slice.primary.featured_image} richText={slice.primary.text_content} />
                        </div>;
                    case 'how_does_it_work_process':
                        return <div id="how-does-it-works" key={index}>
                            <HowDoesItWork title={slice.primary.section_title.text} items={slice.items} />
                        </div>;
                    case 'pricing':
                        return <div id="pricing" key={index}>
                            <Pricing title={slice.primary.section_title.text} items={slice.items} />
                        </div>;
                    case 'system_requirements_table':
                        return <div id="sysreq" key={index}>
                            <SystemRequirements title={slice.primary.section_title.text} subtitle={slice.primary.section_subtitle} textContent={slice.primary.text_content} />
                        </div>;
                    case 'sliding_gallery':
                        console.log(slice.items)
                        return <div id="powered-by" key={index}>
                            <PoweredBy title={slice.primary.section_title.text} subtitle={slice.primary.section_subtitle} images={slice.items} />
                        </div>;
                    default:
                        return null;
                }

            })}

        </Layout>
    )

}

export const query = graphql`
  query MyIndexQuery {
    prismicHomepage {
      data {
        body {
          ... on PrismicHomepageBodyFeatures {
            id
            items {
              feature_title
              feature_description {
                html
                raw
                text
              }
              feature_icon {
                url
                alt
              }
            }
            primary {
              section_title {
                html
                raw
                text
              }
            }
            slice_type
          }
          ... on PrismicHomepageBodySlidingGallery {
            id
            slice_type
            items {
              image_poster {
                url
                alt
              }
            }
            primary {
              section_title {
                text
                raw
                html
              }
              section_subtitle {
                text
                raw
                html
              }
            }
          }
          ... on PrismicHomepageBodySystemRequirementsTable {
            id
            slice_type
            primary {
              text_content {
                raw
                text
                html
              }
              section_title {
                text
                raw
                html
              }
              section_subtitle {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyPricing {
            id
            slice_type
            primary {
              section_title {
                text
                raw
                html
              }
            }
            items {
              unit
              price_amount
              plan_title
              plan_tag
              plan_subtitle
              plan_description {
                text
                raw
                html
              }
            }
          }
          ... on PrismicHomepageBodyHowDoesItWorkProcess {
            id
            slice_type
            primary {
              section_title {
                text
                raw
                html
              }
            }
            items {
              title
              text_content {
                text
                raw
                html
              }
              icon {
                url
                alt
              }
            }
          }
          ... on PrismicHomepageBodyFeaturedImage {
            id
            primary {
              title {
                text
                raw
                html
              }
              text_content {
                html
                raw
                text
              }
              featured_image {
                url
                alt
                fluid(maxWidth: 648, maxHeight: 364) {
                    ...GatsbyPrismicImageFluid
                }
              }
            }
            slice_type
          }
        }
      }
    }
  }
`

export default IndexPage
